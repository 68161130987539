import type { Site } from '$lib/types/site';
// import type { Giscus } from '$lib/types/giscus';
import type { DD } from '$lib/types/dd';
import TitleSVG from '$assets/logo.svg';

import Avatar from '$assets/avatar.png';
import Avatar_128 from '$assets/avatar.png?w=128&h=128&format=avif;webp&imagetools';
import Avatar_48_PNG from '$assets/avatar.png?w=48&h=48&imagetools';
import Avatar_96_PNG from '$assets/avatar.png?w=96&h=96&imagetools';
import Avatar_192_PNG from '$assets/avatar.png?w=192&h=192&imagetools';
import Avatar_512_PNG from '$assets/avatar.png?w=512&h=512&imagetools';

import SiteCover from '$assets/backgroundpicture.png';

export const siteConfig: Site.Config = {
  url: 'https://www.gooayetranscript.com/',
  title: `Gooaye Transcript | 股癌 Podcast 逐字稿分享`,
  titleSVG: TitleSVG,
  subtitle: `Gooaye Transcript`,
  description: `股癌 Podcast 逐字稿網站 - 輕鬆查找股癌 Podcast重點整理。提供節目中提過的旅遊景點、餐廳、電影、書籍、音樂等完整內容索引，讓您快速找到感興趣的節目片段。歡迎使用本站進行深度搜尋，掌握股癌精華內容。`,
  lang: 'zh',
  timeZone: 'Asia/Taipei',
  since: 2024,
  cover: SiteCover,
  author: {
    name: 'Trent',
    status: '❤️',
    statusTip:
      '<a href="#" rel="external" style="color:#0F0" onMouseOver="this.style.color=\'#0FF\'" onMouseOut="this.style.color=\'#0F0\'" >Trent</a> is Awesome !',
    avatar: Avatar,
    avatar_128: Avatar_128,
    avatar_48_png: Avatar_48_PNG,
    avatar_96_png: Avatar_96_PNG,
    avatar_192_png: Avatar_192_PNG,
    avatar_512_png: Avatar_512_PNG,
    website: 'https://www.gooayetranscript.com/',
    github: 'https://github.com/sisyphusla',
    email: 'hi@gooayetranscript.com',
    bio: `股癌Podcast的歷史本文。`,
  },
};

export const headConfig: Site.Head = {
  // Used for IndieWeb
  me: ['https://www.gooayetranscript.com/'],
  custom: ({ dev }) =>
    dev
      ? [
          // For Development Environment
        ]
      : [
          // For Production Environment
          `<script>
          (function() {
            var ga4Script = document.createElement('script');
            ga4Script.async = true;
            ga4Script.src = 'https://www.googletagmanager.com/gtag/js?id=G-17FSE3XCX4';
            ga4Script.onerror = function() {
              
            };
            document.head.appendChild(ga4Script);

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-17FSE3XCX4');
          })();
        </script>`,
        ],
};

export const dateConfig: Site.DateConfig = {
  toPublishedString: {
    locales: 'zh-TW',
    options: {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      timeZone: `${siteConfig.timeZone}`,
    },
  },
  toUpdatedString: {
    locales: 'zh-TW',
    options: {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      timeZone: `${siteConfig.timeZone}`,
    },
  },
};

// Replace with your own Giscus setting
// See https://giscus.app/
// export const giscusConfig: Giscus.Config = {
//   enable: true,
//   id: 'giscus-comment',
//   repo: 'sisyphusla/trent.dev',
//   repoId: 'R_kgDOKnDt8g',
//   category: 'General',
//   categoryId: 'DIC_kwDOKnDt8s4CajnE',
//   mapping: 'pathname',
//   reactionsEnabled: '1',
//   emitMetadata: '0',
//   inputPosition: 'top',
//   loading: 'lazy',
//   lang: 'zh-TW',
//   'data-strict': '0',
// };

export const navConfig: (DD.Nav | DD.Link)[] = [
  {
    name: '關於本站',
    url: '/about',
  },
];

export const mobilenavConfig: DD.Nav = {
  orientation: 2,
  links: [
    {
      name: '關於本站',
      url: '/about',
    },
  ],
};
